import React from "react";
import { GetSiteImages } from 'hooks/GetSiteImages'
import { GatsbyImage } from "gatsby-plugin-image";

// original source from: https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05?m=MTU1MjY0MTA1NTE3Ng==

const Image = ({ imgName, alt, className }) => {
  const { edges } = GetSiteImages()
  const imageNode = edges.find(
    edge => edge.node.resize.originalName === imgName
  )
  if (!imageNode) {
    return null
  }

  return (
    <GatsbyImage
      image={imageNode.node.gatsbyImageData}
      alt={alt}
      className={className} />
  );
}
export default Image