import {
  main,
  mainRaised,
  mrAuto,
  mlAuto,
  container,
  section,
  description,
  blackColor,
  whiteColor,
  grayColor,
  cardTitle,
  hexToRgb
} from "assets/jss/material-kit-pro-react.js";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

const styles = {
  ...imagesStyles,
  main,
  mainRaised,
  mrAuto,
  mlAuto,
  description,
  title: {
      color: whiteColor,
      textDecoration: "none",
      fontWeight: "700",
      marginTop: "30px",
      marginBottom: "25px",
      minHeight: "32px",
      fontFamily: `"Roboto Slab", "Times New Roman", serif`
  },
  titleDescription: {
        color: whiteColor,
        textDecoration: "none",
        fontFamily: `"Arial", "Times New Roman", serif`
  },
  section: {
    ...section,
    padding: "70px 0px"
  },
  textCenter: {
    textAlign: "center !important"

  },
  container: {
    ...container,
    zIndex: "2"
  },
  brand: {
    "& h1, & h4": {
      color: whiteColor
    }
  },
  card: {},
  subscribeButton: {},
  cardBody: {
    padding: "15px",
    "& form": {
      marginBottom: "0"
    }
  },
  cardForm: {
    margin: "0 0 0 14px",
    padding: 0,
    top: 10
  },
  cardTitle: {
      ...cardTitle,
      textAlign: "center",
      marginBottom: "0px !important"
  },
  cardDescription: {
      color: grayColor[0],
      textAlign: "center"
  },
  subscribeLine: {
    padding: "1.875rem 0px",
    "& $card": {
      marginTop: "30px"
    },
    "& form": { margin: "0px" },
    "&$subscribeLineImage:after": {
      position: "absolute",
      zIndex: 1,
      width: "100%",
      height: "100%",
      display: "block",
      left: 0,
      top: 0,
      content: "''",
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ",0.66)"
    }
  },
  paper: {
    width: '100%',
    overflowX: 'auto',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '10px',
 
  },
  productImg: {
    width: '75px',
  },
  table: {
    minWidth: 100,
    marginRight: '70px',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  subscribeLineImage: {
    position: "relative",
    backgroundPosition: "top center",
    backgroundSize: "cover",
    "& $container": {
      zIndex: 2,
      position: "relative"
    },
    "& $title": {
      color: whiteColor
    },
    "& $description": {
      color: grayColor[0]
    }
  },
  socialFeed: {
    "& p": {
      display: "table-cell",
      verticalAlign: "top",
      overflow: "hidden",
      paddingBottom: "10px",
      maxWidth: 300
    },
    "& i": {
      fontSize: "20px",
      display: "table-cell",
      paddingRight: "10px"
    }
  },
  img: {
    width: "20%",
    marginRight: "5%",
    marginBottom: "5%",
    float: "left"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  aClass: {
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  }
};

export default styles;
