import { useStaticQuery, graphql } from 'gatsby'

export const GetSiteImages = () => {
    const { allImageSharp } = useStaticQuery(
      graphql`
        query allSiteImages {
            allImageSharp {
                edges {
                    node {
                        gatsbyImageData(formats: AUTO, placeholder: BLURRED)
                        resize {
                          originalName
                        }
                    }
                }
            }
        }
    `)
    return allImageSharp
}