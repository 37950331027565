import React from "react";
import { Link, graphql } from "gatsby"
import { GatsbySeo, BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import { GetSiteMetadata } from 'hooks/GetSiteMetadata';
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"

// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Image from 'components/Image/Image';
import Grid from '@material-ui/core/Grid';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import HeaderTemplate from "components/Header/HeaderTemplate.js";
import HeaderScentsy from "components/Header/HeaderScentsy.js";
import FooterTemplate from "components/Footer/FooterTemplate.js";

import { Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Hidden from "@material-ui/core/Hidden";

import useIsClient from 'hooks/useIsClient';

import styles from "assets/jss/material-kit-pro-react/views/productFamilyFragranceTemplate.js";

const useStyles = makeStyles(styles);

// define options for documentToReactComponents (json > richText)
const options = {
  renderNode: {
    "embedded-asset-block": (node) => {
      const alt = node.data.target.fields.title['en-CA']
      const url = node.data.target.fields.file['en-CA'].url

      return <img alt={alt} src={url} />
    }
  }
}

///// FOR PRODUCT TABLE

// define data model
function createData(name, url, productTypeSlug, description, price, img, hasFragrance, fragranceSlug ) {
  return { name, url, productTypeSlug, description, price, img, hasFragrance, fragranceSlug};
}

// create array to store fragrance data
const rows = [];

// load data into array
function loadTableData(props) {
  rows.length = 0;
  if (props.pageContext.locale.oglanguageCode === "en_US") {
      props.data.allProductsCsv.edges.map((edge) => (
          rows.push(
              createData(
                  edge.node.name,
                  props.pageContext.locale.path + '/shop/' + edge.node.slug,
                  edge.node.productTypeSlug,
                  edge.node.shortDescription,
                  edge.node.price_usd,
                  getImage(edge.node.image, edge.node.name),
                  edge.node.hasFragrance,
                  edge.node.fragranceSlug
              )
          )
      ))
    } else {
      props.data.allProductsCsv.edges.map((edge) => (
          rows.push(
              createData(
                  edge.node.name,
                  props.pageContext.locale.path + '/shop/' + edge.node.slug,
                  edge.node.productTypeSlug,
                  edge.node.shortDescription,
                  edge.node.price,
                  getImage(edge.node.image, edge.node.name),
                  edge.node.hasFragrance,
                  edge.node.fragranceSlug
              )
          )
      ))
    }

}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function enrichProductName(productTypeSlug, name) {
    var productName;
    var productType;
    switch (productTypeSlug) {
        case "warmer":
            productName = name.replace("Warmer", "");
            productType = "Scentsy Warmer";
            break;
        case "element-warmer":
            productName = name.replace("Warmer", "");
            productType = "Scentsy Warmer";
            break;
        case "mini-warmer":
            productName = name.replace("Mini Warmer", "");
            productType = "Scentsy Mini Warmer";
            break;
        case "baseball-warmer":
            productName = name.replace("Warmer", "");
            productType = "Scentsy Warmer";
            break;
        case "scentsy-bar":
            productName = name.replace("Scentsy Bar", "");
            productType = "Scentsy Bar";
            break;
        case "diffuser":
            productName = name.replace("Diffuser", "");
            productType = "Scentsy Diffuser";
            break;
        case "deluxe-diffuser":
            productName = name.replace("Diffuser", "");
            productType = "Scentsy Diffuser";
            break;
        case "scentsy-go":
            productName = name.replace("Diffuser", "");
            productType = "Scentsy Portable Pod Diffuser";
            break;
        case "wall-fan":
            productName = name.replace(" – Wall Fan Diffuser", "");
            productName = productName.replace("Diffuser ", "");
            productType = "Scentsy Wall Fan Diffuser";
            break;
        case "mini-fan-diffuser":
            productName = name.replace("Mini Fan Diffuser – ", "");
            productType = "Scentsy Portable Mini Fan Diffuser";
            break;
        case "scentsy-buddy":
            productName = name.replace("", "");
            productType = "";
            break;
        case "buddy-clip":
            productName = name.replace("", "");
            productType = "";
            break;
        case "blankie-buddy":
            productName = name.replace("", "");
            productType = "";
            break;
        default:
            productName = name.replace("Warmer", "");
            productType = "Scentsy Warmer";
    }

    return (

        <>
        { productName }
        <br />
        <font size="2">
        { productType }
        </font>
        </>

    )

}

function GridDisplay(props) {

    const classes = useStyles();
    const { allFragrancesCsv, order, orderBy } = props;

    return (

        <GridContainer>

            {stableSort(rows, getSorting(order, orderBy)).map((row, index) => (
                <GridItem xs={9} sm={6} md={3} lg={3} key={row.name}>

                    <Link to={row.url}>
                    <Card plain product>
                      <CardHeader noShadow image>
                        {row.img}
                     </CardHeader>

                      <CardBody plain>
                          <Typography variant="body1" component="h2" className={classes.cardTitle}>
                              { enrichProductName(row.productTypeSlug, row.name) }
                              <font size="2">
                              &nbsp;- {Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(row.price)}
                              </font>
                          </Typography>

                          <Hidden xsDown implementation="js">
                            <div className={classes.description}>
                                {
                                  row.hasFragrance === "true" &&
                                    getFragranceDescription(allFragrancesCsv, row.fragranceSlug)

                                }
                                {
                                  (row.description.length >= 150 && row.hasFragrance !== "true") &&
                                    row.description.slice(0,150) + "... "
                                }
                                {
                                  (row.description.length >= 150 && row.hasFragrance !== "true") &&
                                    (
                                      <Link underline='hover' to={row.url + "/" }>(MORE)</Link>
                                    )
                                }
                                {
                                  (row.description.length < 150 && row.hasFragrance !== "true") &&
                                    row.description
                                }
                            </div>
                          </Hidden>
                      </CardBody>

                    </Card>
                    </Link>
                </GridItem>

            ))}

        </GridContainer>

    )
}

function getImage(imageName, altText) {
  const image = (
    <Image
      imgName={imageName}
      alt={altText}
      className="classes.imgFluid"
    />
  )
  return image
}

function getFragranceDescription(allFragrancesCsv, fragranceSlug) {
  const fragrance = allFragrancesCsv.edges.find(
    edge => edge.node.slug === fragranceSlug
  )
  if (!fragrance) {
    return null
  }
  return fragrance.node.shortDescription
}

export default function ProductFamilyListTemplate(props) {

  const classes = useStyles();

  loadTableData(props);

  const { isClient } = useIsClient();
  if (isClient) {
    var isMobile = window.matchMedia('(max-width: 600px)').matches;
    var bannerImage;
    if (isMobile) {
      bannerImage = getSrc(props.data.bannerImageMobile)
    } else {
      bannerImage = getSrc(props.data.bannerImage)
    }
  }

  var appendToH1;
  if (props.pageContext.locale.ogLanguageCode === "en_CA") {
      appendToH1 = "Scentsy Catalog Canada";
  } else {
      appendToH1 = "Scentsy Catalog USA";
  }

  // for SEO path
  const { siteMetadata } = GetSiteMetadata();
  var path = props.location.pathname;
  if (path.includes("/usa")) {
    path = path.replace("/usa", "");
  }

    return (
      <div>

        <HeaderTemplate
          locale={props.pageContext.locale}
          location={props.location}
        />
        <GatsbySeo
          title={props.data.contentfulProductList.name + props.pageContext.locale.appendToTitle}
          description={props.data.contentfulProductList.shortDescription}
          canonical={siteMetadata.siteUrl + props.pageContext.locale.path + path}
          language={props.pageContext.locale.isoLanguageCode}
          languageAlternates={[
            {
              hrefLang: props.pageContext.locale.isoLanguageCode,
              href: siteMetadata.siteUrl + props.pageContext.locale.path + path,
            },
            {
              hrefLang: props.pageContext.altLocale.isoLanguageCode,
              href: siteMetadata.siteUrl + props.pageContext.altLocale.path + path,
          }
          ]}
          openGraph={{
            type: 'website',
            title: props.data.contentfulProductList.name + props.pageContext.locale.appendToTitle,
            description: props.data.contentfulProductList.shortDescription,
            url: siteMetadata.siteUrl + props.pageContext.locale.path + path,
            site_name: 'SCENTSY Online Store | Home Fragrance Biz',
            locale: props.pageContext.locale.ogLanguageCode,
            images: [
              {
                url: siteMetadata.siteUrl + props.pageContext.image,
                alt: 'Picture of ' + props.data.contentfulProductList.name,
                width: 600,
                height: 600
              }]
          }}
        />
        <BreadcrumbJsonLd
          itemListElements={[
            {
              position: 1,
              name: 'Home',
              item: 'https://homefragrancebiz.com',
            },
            {
              position: 2,
              name: props.data.contentfulProductList.name,
            },
          ]}
        />
        <Parallax
          image={bannerImage}
          filter="dark"
          small
          //style={{ paddingTop: "3%", height: "70vw", maxHeight: "500px" }}
        >
          <div className={classes.container}>
              <HeaderScentsy />
              <GridContainer>
                <GridItem
                  md={8}
                  sm={8}
                  className={classNames(
                    classes.mlAuto,
                    classes.mrAuto,
                    classes.textCenter
                  )}
                >
                  <div className={classes.brand}>
                    <Hidden only={['sm','md','lg','xl']} implementation="js">
                      <Typography variant="h5" component="h1" className={classes.title}>
                        {props.data.contentfulProductList.name}
                      </Typography>
                    </Hidden>
                    <Hidden only={['xs']} implementation="js">
                      <h1 className={classes.title}>
                        {props.data.contentfulProductList.name}
                      </h1>
                    </Hidden>
                    <Hidden only={['xs']} implementation="js">
                      <Typography variant="caption" component="p" className={classes.titleDescription}>
                        {props.data.contentfulProductList.shortDescription}
                      </Typography>
                    </Hidden>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>

          <div className={classNames(classes.main, classes.mainRaised)}>

            <Paper className={classes.paper}>

              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">

                <Typography color="textPrimary" variant="subtitle2" component="h1">
                  {props.data.contentfulProductList.name.toUpperCase()}
                  <Hidden smDown implementation="js">
                    <font color="#777777" size="1"><i> {appendToH1}</i></font>
                  </Hidden>
                </Typography>

              </Breadcrumbs>

              <hr></hr>

              <Hidden xsDown implementation="js">
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={3}

                >
                  <Grid item xs={8} sm={4}>
                    <GatsbyImage
                      image={props.data.contentfulProductList.image.gatsbyImageData}
                      alt={props.data.contentfulProductList.name} ></GatsbyImage>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="h4" component="p">{props.data.contentfulProductList.name}</Typography>
                    {renderRichText(props.data.contentfulProductList.richDescription, options)}
                  </Grid>

                </Grid>

                <hr></hr>
              </Hidden>

              <GridDisplay allFragrancesCsv={props.data.allFragrancesCsv} order="desc" orderBy="price"/>

            </Paper>

        </div>

        <FooterTemplate
          locale={props.pageContext.locale}
        />
      </div>
    );
}

export const query = graphql`
  query ($slug: String!, $bannerImageName: String, $queryRegex: String!) {
    allProductsCsv(filter: {productTypeSlug: {regex: $queryRegex}, isDiscontinued: {eq: "false"}, isHidden: {eq: "false"}}, sort: {order: ASC, fields: name}) {
      edges {
        node {
          name
          slug
          shortDescription
          image
          productTypeSlug
          price
          price_usd
          fragranceSlug
          hasFragrance
        }
      }
    }
    allFragrancesCsv {
      edges {
        node {
          slug
          name
          shortDescription
        }
      }
    }
    bannerImage: imageSharp(resize: {originalName: {eq: $bannerImageName}}) {
        gatsbyImageData(quality: 50)
    }
    bannerImageMobile: imageSharp(resize: {originalName: {eq: $bannerImageName}}) {
      gatsbyImageData(width: 1200)
    }
    contentfulProductList(slug: {eq: $slug}) {
      name
      slug
      image {
        gatsbyImageData(placeholder: BLURRED)
        file {
          fileName
        }
      }
      shortDescription
      richDescription {
        raw
      }
    }
  }
`